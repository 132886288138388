import './header.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import * as actionCreators from '../store/actions/index'

import newslatter from '../images/newslatter.svg';
import MyInput from './MyInput';
import ButtonLoader from './buttonLoader';
import ErrrorHandel from './ErrrorHandel';

class NewsLatter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
    };
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  submit = async(model) => {
    const { showButtonLoader, hideButtonLoader, showSuccessPopup } = this.props;
    showButtonLoader();
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/subscribe'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
    await fetch(uri,{
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: model.email
        })
      })
    .then(res => res.json())
    .then((res) => {
      const payload = {
        message: 'You are successfully Subscribed.',
        btnLabel: 'Ok'
      }
      showSuccessPopup(payload);
      this.refs['newsLetterFrm'].reset();
      hideButtonLoader();
    });
  }

  render() {
    const { canSubmit } = this.state;
    const { buttonLoader } = this.props;
    
    return (
      <section className="newslatter-container">
        <Formsy ref="newsLetterFrm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
          <ErrrorHandel formName={'newsLetterFrm'} self={this} />
          <div className="container">
            <div className="newslatter-content" >
              <div className="left">
                <img src={newslatter} alt="newslatter" />
              </div>
              <div className="right" style={{ position: 'relative' }}>
                <h3 className="title">Join our newsletter</h3>
                {/* <p className="">{'Get our best tips & advice in your inbox'}</p> */}
                <div className="text-field">
                  <MyInput
                    name="email"
                    id="email"
                    placeholder="Enter your e-mail address"
                    type="text"
                    validations="isEmail"
                    validationError="Please enter valid email"
                    value={''}
                    isLabelRequired={false}
                    className="form-control"
                    errorClassName={'front-error'}
                    required
                  />
                  
                  <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'disabled btn join-btn primary' : 'btn join-btn primary'}>
                    <ButtonLoader isLoading={(canSubmit && buttonLoader)?true:false} label={'Join'} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Formsy>
      </section>
    )

  }

}


const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader()),
    showSuccessPopup: (payload) => dispatch(actionCreators.showSuccessPopup(payload)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsLatter);

